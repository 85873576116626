import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import ucwords from 'ucwords';

import { ContextData } from './ContextData';

var moment = require("moment");

const Reminders = () => {

    const context_data = useContext(ContextData)

    useEffect(() => {
        if (localStorage.getItem("login_data")) {
            var login_data = JSON.parse(localStorage.getItem("login_data"))
            getAllRemindersEmployee(login_data.employee_id);
        } else {
            navigate("/logout")
        }
    }, [])

    var navigate = useNavigate();

    const [allReminders, setAllReminders] = useState(null);

    const getAllRemindersEmployee = async (employee_id) => {
        context_data.setProcessing(true)
        const getAllRemindersEmployeeUrl = global.config.server_url + "getAllRemindersEmployee.php";
        axios.post(getAllRemindersEmployeeUrl, { login_employee_id: employee_id }).then((allRemindersResponse) => {
            if (allRemindersResponse.data != false) {
                setAllReminders(allRemindersResponse.data);
                context_data.setProcessing(false)
            } else {
                setAllReminders(false);
                context_data.setProcessing(false)
            }
        }).catch(err => {
            console.log(err);
            context_data.setProcessing(false)
        });

    }

    const showReminderData = (reminder, showData) => {
        try {
            let reminderData = JSON.parse(reminder);
            return reminderData[showData];
        } catch (error) {
            console.log(reminder, showData)
            console.log(error)
            return "invalid data";
        }
    }

    const showReminderDate = (reminder) => {
        let reminderData = JSON.parse(reminder);
        let value = reminderData.reminder
        if (moment(value).isValid()) {
            return moment(value).format("DD-MMM-YYYY h:mm:ss A")
        } else if (moment(value, "YYYY-MM-DD h:mm:ss A", true).isValid()) {
            return moment(value, "YYYY-MM-DD h:mm:ss A", true).format("DD-MMM-YYYY h:mm:ss A")
        } else if (moment(value, "YYYY-MM-DD H:mm:ss", true).isValid()) {
            return moment(value, "YYYY-MM-DD H:mm:ss", true).format("DD-MMM-YYYY h:mm:ss A")
        } else {
            return value
        }
    }

    // const showReminderDate = (reminder) => {
    //     let reminderData = JSON.parse(reminder);
    //     var data = moment(reminderData.reminder, "YYYY-MM-DD h:mm:ss A", true).format("DD-MMM-YYYY h:mm:ss A")
    //     if (data == "Invalid date") {
    //         data = moment(reminderData.reminder, "YYYY-MM-DD H:mm:ss", true).format("DD-MMM-YYYY h:mm:ss A")
    //     }
    //     return data;
    // }

    const compareDate = (stringDate) => {
        var string_date = "";

        if (moment(stringDate).isValid()) {
            string_date = moment(stringDate).format("DD-MMM-YYYY h:mm:ss A")
        } else if (moment(stringDate, "YYYY-MM-DD h:mm:ss A", true).isValid()) {
            string_date = moment(stringDate, "YYYY-MM-DD h:mm:ss A", true).format("DD-MMM-YYYY h:mm:ss A")
        } else if (moment(stringDate, "YYYY-MM-DD H:mm:ss", true).isValid()) {
            string_date = moment(stringDate, "YYYY-MM-DD H:mm:ss", true).format("DD-MMM-YYYY h:mm:ss A")
        } else {
            string_date = stringDate
        }
        var result = ((moment(string_date, "DD-MMM-YYYY h:mm:ss A")).diff(moment()) < 0)
        console.log(result)
        return result
    }

    // const compareDate = (stringDate) => {
    // var string_date = moment(stringDate, "YYYY-MM-DD h:mm:ss A")
    // var current_date = moment()
    // var diff = string_date.diff(current_date)
    // if (diff < 0) return true
    // else return false
    // }

    return (
        <div >
            <div className="row border">
                <div className="col-12">
                    <h5 className="text-center "><u>PENDING ACTIVITY LIST</u></h5>
                    <div id="div_home_pending_activity">
                        {(allReminders == false || allReminders == null) ? (<div><h6 className="text-center m-5">No Reminders</h6></div>) :
                            (allReminders.map((reminder, index) =>
                                <div className={(compareDate(showReminderData(reminder.next_reminder, "reminder"))) ? "p-2 pending_activity row border mt-2 mb-2 alert alert-danger" : "p-2 pending_activity row border mt-2 mb-2"} >
                                    <div className="col-10">
                                        <label name="nextReminder_activity" id="nextReminder_activity">
                                            <b>{ucwords(showReminderData(reminder.next_reminder, "activity"))}:</b>
                                            &nbsp;<b><u>{(reminder.name)}&nbsp;({(reminder.id)})</u></b>
                                        </label><br />
                                        <label name="nextReminder_reminder" id="nextReminder_reminder">Reminder: {(showReminderDate(reminder.next_reminder))}</label>
                                    </div>
                                    <div className="col-2 p-0 my-auto">
                                        <input type="button" onClick={() => { navigate({ pathname: "/leadDetails", search: 'id=' + reminder.id, state: { id: reminder.id } }) }} className="leads_list_btn w-auto btn btn-success px-1 " value="Details" />
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        </div >
    )
}

export default Reminders