import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import { ContextData } from './Components/ContextData';
import { useNavigate } from 'react-router-dom';
import Breadcrumb from 'react-bootstrap/Breadcrumb'

const $ = require('jquery');
$.DataTable = require('datatables.net');

function WebsiteApi() {

    var navigate = useNavigate();
    const context_data = useContext(ContextData)
    const [userRightStatus, setUserRightStatus] = useState(false);

    const [loginData, setLoginData] = useState(null);

    $(document).ready(function () {
        $('#api_data_list').DataTable();
    });


    useEffect(() => {
        if (localStorage.getItem("login_data")) {
            setLoginData(localStorage.getItem("login_data"))
            var login_data = JSON.parse(localStorage.getItem("login_data"))
            setLoginData(login_data)

            var isAvailable;

            if (login_data.employee_type == "MASTERUSER") {
                isAvailable = true;
            } else if (login_data.employee_rights) {
                var employee_rights_arr = (login_data.employee_rights).trim().split(",");
                var regex = new RegExp(employee_rights_arr.join("|"), "i");
                isAvailable = regex.test("ADD_PROJECT_CAMPAIGN") || login_data.employee_type == "MASTERUSER";
            } else {
                navigate("/noAccess")
            }
            setUserRightStatus(isAvailable)
            if (isAvailable != true) { navigate("/noAccess") }
            getProjectList();
            getApiData();
        } else {
            navigate("/logout")
        }

        if (!localStorage.getItem("employee_id")) {
            navigate("/logout");
        }
    }, []);

    const [selectedBuilder, setSelectedBuilder] = useState(null);

    const getBuilderName = (e) => {
        let data = e.target.options[e.target.selectedIndex].index;
        data = data - 1;
        setSelectedBuilder(projectData[data]);
    }

    const [isPendingProject, setIsPendingProject] = useState(true);
    const [projectData, setProjectData] = useState(null);

    const getProjectList = () => {
        const getProjectListUrl = global.config.server_url + "getProjects.php";
        axios.post(getProjectListUrl, { all_data_active: true }).then((projectListResponse) => {
            setProjectData(null);
            if (projectListResponse.data.length > 0) {
                for (let i = 0; i < projectListResponse.data.length; i++) {
                    setProjectData(projectListResponse.data);
                }
                setIsPendingProject(false);
            } else {
                setProjectData(false);
            }
        }).catch(err => { console.log(err); setProjectData(false); });
    }

    const [apiData, setApiData] = useState(null);
    const [isPendingApiData, setIsPendingApiData] = useState(null);

    const getApiData = async () => {
        const getApiDataUrl = global.config.server_url + "getApiData.php";
        axios.post(getApiDataUrl, { all_data: true }).then((apiDataResponse) => {
            if (apiDataResponse.data != false) {
                setApiData(apiDataResponse.data);
                setIsPendingApiData(false);
            } else {
                setApiData(false);
            }
        }).catch(err => { console.log(err); setApiData(false); });
    }

    const submitAddAPI = async (e) => {
        e.preventDefault();
        if (e.target.select_project.value == 0) {
            alert("Select Project");
            return;
        }

        context_data.setProcessing(true);

        let project_id = e.target.select_project.value;
        let project_name = e.target.select_project.options[e.target.select_project.selectedIndex].text;
        let builder_id = e.target.builder_id.value;
        let builder_name = e.target.builder_name.value;
        let api_username = e.target.api_username.value;
        let api_password = e.target.api_password.value;

        const domain = window.location.host;
        let server_url = "http://" + domain + "/addLeadAPI.php?";
        let api_link = server_url + 'api_username=' + api_username + '&api_password=' + api_password + '&builder_id=' + builder_id + '&project_id=' + project_id + '&salutation={salutation_of_client}&name={name_of_client}&mobile={mobile_of_client_MANDITORY}&whatsapp={whatsapp}&email={email}&utm_source={utm_source}&utm_medium={utm_medium}&utm_campaign={utm_campaign}&utm_term={utm_term}&utm_content={utm_content}&utm_site={utm_site}&utm_url={utm_url}&utm_title={utm_title}&utm_timestamp={utm_timestamp}&utm_itemID={utm_itemID}';

        let addApiData = {
            project_id: project_id,
            project_name: project_name,
            builder_id: builder_id,
            builder_name: builder_name,
            api_username: api_username,
            api_password: api_password,
            api_link: api_link,
            login_employee_id: localStorage.getItem("employee_id") ? localStorage.getItem("employee_id").toUpperCase() : "",
            login_employee_name: localStorage.getItem("employee_name") ? localStorage.getItem("employee_name").toUpperCase() : "",
        }

        const addApiUrl = global.config.server_url + "addWebsiteApi.php";
        axios.post(addApiUrl, addApiData).then((addApiResponse) => {
            if (addApiResponse.data == true) {
                alert("Added");
                context_data.setProcessing(false);
                window.location.reload();
                // getApiData();

            } else {
                alert("Error in Adding");
                context_data.setProcessing(false);
            }
        }).catch(err => {
            alert("Error in Adding");
            context_data.setProcessing(false);
            console.log(err);
        }
        );

    }

    const copyApiLink = (e) => {
        let apiLink = apiData[e].api_link;

        let tmp = document.createElement('textarea');
        tmp.value = apiLink;
        tmp.setAttribute('readonly', '');
        tmp.style.position = 'absolute';
        tmp.style.left = '-9999px';
        document.body.appendChild(tmp);
        tmp.select();
        document.execCommand('copy');
        document.body.removeChild(tmp);
        console.log(`${apiLink} copied.`);
        alert("Link Copied");
    }

    const deleteWebswiteApi = (table_id) => {
        context_data.setProcessing(true);

        const deleteWebsiteApiUrl = global.config.server_url + "deleteWebsiteApi.php";
        axios.post(deleteWebsiteApiUrl, { api_table_id: table_id }).then((deleteWebsiteApiResponse) => {
            if (deleteWebsiteApiResponse.data == true) {
                alert("Deleted");
                context_data.setProcessing(false);
                window.location.reload();

            } else {
                alert("Error in Deleting");
                context_data.setProcessing(false);
            }
        }).catch(err => {
            alert("Error in Deleting");
            context_data.setProcessing(false);
            console.log(err);
        }
        );
    }

    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <Breadcrumb>
                            <Breadcrumb.Item onClick={() => navigate("/home")}>Home</Breadcrumb.Item>
                            <Breadcrumb.Item active>Website API</Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                </div>
            </div>
            <div>
                <form className="form-group" onSubmit={(e) => submitAddAPI(e)}>
                    <div className="container-fluid">
                        <h4 className="text-center">Create API</h4>
                        <div className="row ">
                            <div className="col-md-2"></div>
                            <div className="border col-md-8 pb-3">
                                <div className="row mt-2">
                                    {(projectData == false || projectData == null) ? ("No Project Available") : (
                                        <div className="col-12">
                                            Project Name *
                                            <select className="form-control" onChange={(e) => { getBuilderName(e); }} name="select_project" required>
                                                {(isPendingProject) ? <option disabled selected >Loading...</option> : ""}
                                                <option selected disabled value="0">Select Project</option>
                                                {projectData.map((project, index) =>
                                                    <option index={index} value={project.builder_project_id} data-sale_type={project.sale_type} >{project.builder_project_name}</option>
                                                )}
                                            </select>
                                        </div>
                                    )}
                                </div>
                                {(selectedBuilder == null) ? ("") : (
                                    <div className="row mt-2">
                                        <div className="col-12">
                                            Builder Name: <b>{selectedBuilder.builder_name}</b>
                                            <input type="hidden" name="builder_id" className="form-control" value={selectedBuilder.builder_id} />
                                            <input type="hidden" name="builder_name" className="form-control" value={selectedBuilder.builder_name} />

                                        </div>
                                    </div>

                                )}

                                <div className="row mt-2">
                                    <div className="col-12">
                                        API UserName *
                                        <input required type="text" name="api_username" id="api_username" className="form-control" placeholder="API Username *" />
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-12">
                                        API Password *
                                        <input required type="text" name="api_password" id="api_password" className="form-control" placeholder="API Username *" />
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-12">
                                        <input type="submit" className="btn btn-primary" value="Submit" />
                                    </div>
                                </div>

                            </div>
                            <div className="col-md-2"></div>

                            <div className="col-12 mt-2 border pb-3">
                                {(apiData == null || apiData == false) ? "" : (
                                    <div className="div_api_data_list">
                                        <table id="api_data_list" className="display table table-striped table-bordered compact" width="100%" >
                                            <thead className="bg-dark text-white">
                                                <tr>
                                                    <th>#</th>
                                                    <th>Builder Name</th>
                                                    <th>Project Name</th>
                                                    <th>API Username</th>
                                                    <th>API Password</th>
                                                    <th>API Link</th>
                                                    <th>Created by</th>
                                                    <th>Created at</th>
                                                    <th>Updated by</th>
                                                    <th>Updated at</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {apiData.map((api, index) =>
                                                    <tr key={api.id} >
                                                        <td className="api_data_list_td">{index + 1}</td>
                                                        <td className="api_data_list_td">{(api.builder_name) ? (api.builder_name) : "-"}</td>
                                                        <td className="api_data_list_td">{(api.builder_project_name) ? (api.builder_project_name) : "-"}</td>
                                                        <td className="api_data_list_td">{(api.api_username) ? (api.api_username) : "-"}</td>
                                                        <td className="api_data_list_td">{(api.api_password) ? (api.api_password) : "-"}</td>
                                                        <td className="api_data_list_td" style={{ maxWidth: "200px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>{(api.api_link) ? (api.api_link) : "-"}</td>
                                                        <td className="api_data_list_td">{(api.create_id) ? (api.create_id + " ") : ""}{(api.create_name) ? (" : " + api.create_name) : ""}</td>
                                                        <td className="api_data_list_td">{(api.create_datetime) ? (api.create_datetime, 'yyyy-mm-dd H:MM:ss') : "-"}</td>
                                                        <td className="api_data_list_td">{(api.update_id) ? (api.update_id + " ") : ""}{(api.update_name) ? (" : " + api.update_name) : ""}</td>
                                                        <td className="api_data_list_td">{(api.update_datetime) ? (api.update_datetime, 'yyyy-mm-dd H:MM:ss') : "-"}</td>
                                                        <td className="api_data_list_td">
                                                            <input type="button" className="projects_list_btn btn btn-info m-0 pt-0 pb-0 pl-1 pr-1" onClick={() => copyApiLink(index)} value="Copy" />
                                                            <input type="button" className="projects_list_btn btn btn-info m-0 pt-0 pb-0 pl-1 pr-1" onClick={() => deleteWebswiteApi(api.id)} value="Delete" />
                                                        </td>
                                                    </tr>
                                                )
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                )}

                            </div>

                        </div>
                    </div>
                </form>
            </div>
        </>
    )
}

export default WebsiteApi