
module.exports = global.config = {
    // server_url: "https://poolserver.poolcrm.co.in/poolserver/", 
    // public_folder_path: "https://www.poolcrm.co.in/",

    server_url: "/server/",
    // server_url: "http://localhost/reactapps/poolcrm_react_php/public/server/",
    
    lead_source_list: ["99acres", "Adpushup", "Adgebra", "Facebook", "Google", "Housing", "Instagram", "IVR", "JustDial", "MagicBricks", "Outbrain", "Reference", "SEO", "Sulekha", "Taboola", "Website", "Whatsapp", "Youtube"],

    employee_rights_list: [
        { title: "Add / Edit Lead", empRight: "ADD_LEAD" },
        { title: "Reassign Lead", empRight: "REASSIGN_LEADS" },
        { title: "Add / Edit Employee", empRight: "ADD_EMPLOYEE" },
        { title: "Assign Employee", empRight: "ASSIGN_EMPLOYEE" },
        { title: "Add / Edit Project", empRight: "ADD_PROJECT" },
        { title: "Add / Edit Builder", empRight: "ADD_BUILDER" },
        { title: "Add / Edit Campaign ID", empRight: "ADD_PROJECT_CAMPAIGN" },
    ],
    closed_reasons_list: [
        { reason: "switched off / not reachable" },
        { reason: "lost to competitor" },
        { reason: "wrong enquiry" },
        { reason: "plan drop" },
        { reason: "low budget" },
        { reason: "location mismatch" },
        { reason: "already purchased" },
        { reason: "not interested" },
    ],
    followup_closed_reasons_list: [
        { reason: "switched off / not reachable" },
        { reason: "lost to competitor" },
        { reason: "wrong enquiry" },
        { reason: "plan drop" },
        { reason: "low budget" },
        { reason: "location mismatch" },
        { reason: "already purchased" },
        { reason: "not interested" },
    ],
    sitevisit_closed_reasons_list: [
        { reason: "switched off / not reachable" },
        { reason: "lost to competitor" },
        { reason: "wrong enquiry" },
        { reason: "plan drop" },
        { reason: "low budget" },
        { reason: "location mismatch" },
        { reason: "already purchased" },    
        { reason: "not interested" },
    ],
};