import React, { useContext } from 'react'
import axios from 'axios';
import { useCallback, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import ucwords from 'ucwords';
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import { ContextData } from './Components/ContextData';
import Breadcrumb from 'react-bootstrap/Breadcrumb'

var moment = require('moment')

const BuildersList = (props) => {

	var navigate = useNavigate();

	const context_data = useContext(ContextData)

	const [buildersData, setBuildersData] = useState(false);
	const [loginData, setLoginData] = useState(false);
	const [userRightStatus, setUserRightStatus] = useState(false);
	var gridRef = useRef();

	useEffect(() => {
		if (localStorage.getItem("login_data")) {
			setLoginData(localStorage.getItem("login_data"))
			var login_data = JSON.parse(localStorage.getItem("login_data"))
			setLoginData(login_data)

			var isAvailable;

			if (login_data.employee_type == "MASTERUSER") {
				isAvailable = true;
			} else if (login_data.employee_rights) {
				var employee_rights_arr = (login_data.employee_rights).trim().split(",");
				var regex = new RegExp(employee_rights_arr.join("|"), "i");
				isAvailable = regex.test("ADD_BUILDER") || login_data.employee_type == "MASTERUSER";
			} else {
				navigate("/noAccess")
			}
			setUserRightStatus(isAvailable)
			if (isAvailable != true) { navigate("/noAccess") }
			getBuilders(login_data.employee_type);

		} else {
			navigate("/logout")
		}

		if (!localStorage.getItem("employee_id")) {
			navigate("/logout");
		}
	}, [])

	function getBuilders(employee_type) {
		context_data.setProcessing(true)
		const getBuildersUrl = global.config.server_url + "getBuilders.php";
		axios.post(getBuildersUrl, { all_data: true }).then((getBuildersResponse) => {
			if (getBuildersResponse.data) {
				setBuildersData(getBuildersResponse.data);
				context_data.setProcessing(false)
			} else {
				alert("error in getting data")
				context_data.setProcessing(false)
			}
		}).catch(err => { console.log(err); context_data.setProcessing(false) });
	}

	const [defaultColDef, setDefaultColDef] = useState(
		{
			resizable: true,
			sortable: true,
			autoHeight: true,
		}
	)

	const ucwords_value = (params) => {
		var column_name = (params.column.colId)
		var newValue = params.data[column_name] ? ucwords(params.data[column_name].toLowerCase()).replaceAll('_', ' ') : "-"
		return newValue
	}

	const uppercase_value = (params) => {
		var column_name = (params.column.colId)
		var newValue = params.data[column_name] ? (params.data[column_name].toUpperCase()).replaceAll('_', ' ') : "-"
		return newValue
	}

	const btnRenderer = (params) => {
		let edit_btn = document.createElement("button");
		edit_btn.innerHTML = "Edit";
		edit_btn.classList = "table_list_btn btn btn-info"
		edit_btn.onclick = function () {
			navigate({ pathname: "/editBuilder/", search: "id=" + params.data.id, state: { id: params.data.id } })
		};
		return edit_btn;
	}

	const autoSizeAll = useCallback((skipHeader) => {
		const allColumnIds = [];
		gridRef.current.columnApi.getAllColumns().forEach((column) => {
			allColumnIds.push(column.getId());
		});
		gridRef.current.columnApi.autoSizeColumns(allColumnIds, skipHeader);
	}, []);

	const onFilterTextBoxChanged = useCallback(() => {
		gridRef.current.api.setQuickFilter(
			document.getElementById('filter-text-box').value
		);
	}, []);

	const datetime_value = (params) => {
		var column_name = (params.column.colId)
		if (moment(params.data[column_name]).isValid()) {
			return moment(params.data[column_name]).format("DD-MMM-YYYY h:mm:ss A")
		} else if (moment(params.data[column_name], "YYYY-MM-DD h:mm:ss A", true).isValid()) {
			return moment(params.data[column_name], "YYYY-MM-DD h:mm:ss A", true).format("DD-MMM-YYYY h:mm:ss A")
		} else if (moment(params.data[column_name], "YYYY-MM-DD H:mm:ss", true).isValid()) {
			return moment(params.data[column_name], "YYYY-MM-DD H:mm:ss", true).format("DD-MMM-YYYY h:mm:ss A")
		} else {
			return params.data[column_name]
		}
	}

	return (
		<>
			<div className="container-fluid">
				<div className="row">
					<div className="col-12">
						<Breadcrumb>
							<Breadcrumb.Item onClick={() => navigate("/home")}>Home</Breadcrumb.Item>
							<Breadcrumb.Item active>Builders</Breadcrumb.Item>
						</Breadcrumb>
					</div>
				</div>
			</div>

			<div className="container-fluid">
				<div className="row">
					<div className="col-md-4">
						<input className='btn btn-primary' type="button" value="Add New Builder" onClick={() => navigate("/addBuilder")} />
					</div>
					<div className="col-md-4">
						<h3 className='text-center'>All Builders</h3>
					</div>
					<div className="col-md-4">
						<input className='form-control' type="text" id="filter-text-box" placeholder="Search..." onInput={onFilterTextBoxChanged} />
					</div>
				</div>

				<div className="row">
					<div className="col-12">
						{(buildersData == false || buildersData == null) ? (<div><h3 className="text-center m-5">No Data Available</h3></div>) : (
							<>
								<div className="ag-theme-alpine" style={{ height: "75vh", width: "100%" }}>
									<AgGridReact
										defaultColDef={defaultColDef}
										rowData={buildersData}
										pagination={true}
										paginationAutoPageSize={true}
										animateRows={true}
										ref={gridRef}
										onGridReady={() => autoSizeAll(false)}
										onFirstDataRendered={() => autoSizeAll(false)}
									>
										<AgGridColumn width="100" headerName="Sl No" valueGetter={"node.rowIndex+1"} ></AgGridColumn>
										<AgGridColumn filter='agTextColumnFilter' headerName="Builder ID" field="builder_id" ></AgGridColumn>
										<AgGridColumn filter='agTextColumnFilter' headerName="Name" field="builder_name" valueGetter={ucwords_value} ></AgGridColumn>
										<AgGridColumn filter='agTextColumnFilter' headerName="Address" field="builder_address" valueGetter={ucwords_value} ></AgGridColumn>
										<AgGridColumn filter='agTextColumnFilter' headerName="City" field="builder_city" valueGetter={ucwords_value}></AgGridColumn>
										<AgGridColumn filter='agTextColumnFilter' headerName="GST" field="builder_gst" valueGetter={uppercase_value} ></AgGridColumn>
										<AgGridColumn filter='agTextColumnFilter' headerName="Created By" field="create_name" valueGetter={ucwords_value}></AgGridColumn>
										<AgGridColumn filter='agTextColumnFilter' headerName="Created On" field="create_datetime" valueGetter={datetime_value}></AgGridColumn>
										<AgGridColumn headerName="Actions" cellRenderer={btnRenderer} ></AgGridColumn>
									</AgGridReact>
								</div>
							</>
						)}
					</div>
				</div>
			</div>

		</>
	)
}

export default BuildersList