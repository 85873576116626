import { Button, TextField } from '@mui/material';
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'

import { useNavigate, useSearchParams } from 'react-router-dom';
import { ContextData } from './Components/ContextData';
import Breadcrumb from 'react-bootstrap/Breadcrumb'

var moment = require("moment")

const BuilderEdit = (props) => {
    const context_data = useContext(ContextData)

    var navigate = useNavigate();
    let [searchParams, setSearchParams] = useSearchParams();

    const [buildersData, setBuildersData] = useState(false);
    const [loginData, setLoginData] = useState(false);
    const [userRightStatus, setUserRightStatus] = useState(false);

    useEffect(() => {
        if (localStorage.getItem("login_data")) {
            setLoginData(localStorage.getItem("login_data"))
            var login_data = JSON.parse(localStorage.getItem("login_data"))
            setLoginData(login_data)

            var isAvailable;

            if (login_data.employee_type == "MASTERUSER") {
                isAvailable = true;
            } else if (login_data.employee_rights) {
                var employee_rights_arr = (login_data.employee_rights).trim().split(",");
                var regex = new RegExp(employee_rights_arr.join("|"), "i");
                isAvailable = regex.test("ADD_BUILDER") || login_data.employee_type == "MASTERUSER";
            } else {
                navigate("/noAccess")
            }
            setUserRightStatus(isAvailable)
            if (isAvailable != true) { navigate("/noAccess") }

            var builder_table_id = searchParams.get("id");
            getBuilder(builder_table_id);

        } else {
            navigate("/logout")
        }

        if (!localStorage.getItem("employee_id")) {
            navigate("/logout");
        }
    }, [])

    const [builderData, setBuilderData] = useState(false);

    const getBuilder = async (builder_table_id) => {
        const getBuilderUrl = global.config.server_url + "getBuilders.php";

        axios.post(getBuilderUrl, { builder_table_id: builder_table_id }).then((getBuilderResponse) => {
            if (getBuilderResponse.data) {
                setBuilderData(getBuilderResponse.data[0]);
            }
        }).catch(err => { console.log(err); });
    }

    const submitEditBuilder = (e) => {
        e.preventDefault();

        context_data.setProcessing(true);

        const submitEditBuilderUrl = global.config.server_url + "updateBuilder.php";
        const submitEditBuilderData = {
            builder_id: (e.target.builder_id.value),
            builder_name: (e.target.builder_name.value),
            builder_address: (e.target.builder_address.value),
            builder_city: (e.target.builder_city.value),
            builder_gst: (e.target.builder_gst.value),
            login_employee_id: (localStorage.getItem("employee_id")),
            login_employee_name: (localStorage.getItem("employee_name")),
        }

        axios.post(submitEditBuilderUrl, submitEditBuilderData).then((response) => {
            if (response.data === true) {
                alert("Builder Updated");
                context_data.setProcessing(false);
                navigate({ pathname: "/allBuilders", state: { login_data: loginData } });
            } else {
                alert("Error in Updating Builder");
                context_data.setProcessing(false);
            }
        }).catch(err => { console.log(err); alert("Error in Updating Builder"); context_data.setProcessing(false); });

    }

    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <Breadcrumb>
                            <Breadcrumb.Item onClick={() => navigate("/home")}>Home</Breadcrumb.Item>
                            <Breadcrumb.Item onClick={() => navigate("/allBuilders")}>Builders</Breadcrumb.Item>
                            <Breadcrumb.Item active>Edit Builder</Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <h3 className='text-center'>Edit Builder</h3>
                    </div>
                </div>
                {builderData && (
                    <form onSubmit={submitEditBuilder}>
                        <div className="row ">
                            <div className="col-md-12">
                                <fieldset className='fieldset_info'>
                                    <legend className='fieldset_info_legend'>Builder Information</legend>
                                    <div className="row">

                                        <div className="col-md-6 my-2">
                                            <TextField fullWidth label="Builder ID" name="builder_id" required InputProps={{ readOnly: true, }} InputLabelProps={{ shrink: true }} value={builderData.builder_id} />
                                        </div>
                                        <div className="col-md-6 my-2">
                                            <TextField fullWidth label="Name" name="builder_name" required InputLabelProps={{ shrink: true }} defaultValue={builderData.builder_name} />
                                        </div>
                                        <div className="col-md-6 my-2">
                                            <TextField fullWidth label="Office Address" name="builder_address" required InputLabelProps={{ shrink: true }} defaultValue={builderData.builder_address} />
                                        </div>
                                        <div className="col-md-6 my-2">
                                            <TextField fullWidth label="City" name="builder_city" required InputLabelProps={{ shrink: true }} defaultValue={builderData.builder_city} />
                                        </div>
                                        <div className="col-md-6 my-2">
                                            <TextField fullWidth label="GST Number" name="builder_gst" InputLabelProps={{ shrink: true }} defaultValue={builderData.builder_gst} />
                                        </div>
                                        <div className="col-md-6 my-2">
                                            <Button type="submit" variant="contained" color="success">Submit</Button>
                                        </div>
                                    </div>
                                </fieldset>
                            </div>
                        </div>
                    </form>
                )}
            </div>
        </>
    )
}

export default BuilderEdit