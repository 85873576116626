import { LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { Stack, TextField } from '@mui/material';
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap';
import { useSearchParams } from 'react-router-dom';
import ucwords from 'ucwords';
import { MenuItem } from '@mui/material';
import { ContextData } from './ContextData';

var moment = require("moment");

const CloseLead = (props) => {
    let [searchParams, setSearchParams] = useSearchParams();
    const context_data = useContext(ContextData)

    useEffect(() => {
        var lead_id = searchParams.get("id");
        setLeadId(lead_id)
    }, [])

    const [leadId, setLeadId] = useState(false)

    const [closeLeadShow, setCloseLeadShow] = useState(false);
    const handleCloseLeadClose = () => { document.getElementById("selectLeadStage").value = props.leadDetails.lead_status; setCloseLeadShow(false); }
    const handleCloseLeadShow = () => { props.setClosedReason(""); setCloseLeadShow(true); }

    function saveSelectedLeadStage(e) {
        e.preventDefault();
        props.unblockEmployeeWithLead()

        let selectedData = document.getElementById("selectLeadStage").value;

        let lead_status_change_reason = e.target.lead_status_change_reason.value;
        lead_status_change_reason = lead_status_change_reason.replace(/["']/g, "");
        lead_status_change_reason = lead_status_change_reason.replace(/\s+/g, ' ').trim()

        var id = (leadId != undefined || leadId != null) ? leadId : searchParams.get("id");
        var competitor_name = e.target.competitor_name ? e.target.competitor_name.value : "";
        var competitor_name = e.target.competitor_name ? e.target.competitor_name.value : "";
        competitor_name = competitor_name.replace(/["']/g, "");
        competitor_name = competitor_name.replace(/\s+/g, ' ').trim()

        var location = e.target.location ? e.target.location.value : props.leadDetails.location;
        var min_budget = e.target.min_budget ? e.target.min_budget.value : props.leadDetails.min_budget;
        var max_budget = e.target.max_budget ? e.target.max_budget.value : props.leadDetails.max_budget;

        var current_lead_status = e.target.current_lead_status.value

        const saveLeadStageUrl = global.config.server_url + "changeLeadStage.php";
        const saveLeadStageData = {
            lead_status_change_reason: lead_status_change_reason,
            lead_status: "closed",
            competitor_name: competitor_name,

            location: location,
            min_budget: min_budget,
            max_budget: max_budget,

            current_lead_status: "closed",
            id: id,
            login_employee_id: (localStorage.getItem("employee_id")),
            login_employee_name: (localStorage.getItem("employee_name")),
        };
        context_data.setProcessing(true);

        axios.post(saveLeadStageUrl, saveLeadStageData).then((response) => {
            if (response.data == true) {
                alert("Lead Stage Changed");
                context_data.setProcessing(false);
                handleCloseLeadClose();
                props.getLeadDetails();

            } else {
                alert("Error in Changing Lead Stage");
                context_data.setProcessing(false);
            }
        }).catch(err => { console.log(err); alert("Error in Changing Lead Stage."); context_data.setProcessing(false); });

    }

    return (
        <>
            <Modal
                show={closeLeadShow}
                onHide={() => handleCloseLeadShow()}
                centered
                keyboard={false}
            >
                <Modal.Header >
                    <Modal.Title>Add Reason for Lead Close</Modal.Title>
                </Modal.Header>
                <form className="form-group" onSubmit={saveSelectedLeadStage}>
                    <Modal.Body>
                        <div>

                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <Stack spacing={3}>
                                    {props.leadDetails && (
                                        <>
                                            <input type="hidden" name="current_lead_status" value={props.leadDetails.lead_status.toLowerCase()} />
                                            <TextField
                                                select
                                                name='lead_status_change_reason'
                                                label="Closed Reason"
                                                value={props.closedReason}
                                                onChange={(e) => props.setClosedReason(e.target.value)}
                                                required
                                            >
                                                {global.config.closed_reasons_list && global.config.closed_reasons_list.map((list, index) =>
                                                    <MenuItem key={index} value={list.reason}>{ucwords(list.reason)}</MenuItem>
                                                )}
                                            </TextField>
                                            {(props.closedReason == "lost to competitor") ? (
                                                <TextField label={"Competitor Name"} name="competitor_name" variant="outlined" required />
                                            ) : ""}

                                            {(props.closedReason == "location mismatch") ? (
                                                <TextField label={"Preferred Location"} name="location" variant="outlined" required />
                                            ) : ""}

                                            {(props.closedReason == "low budget") ? (
                                                <>
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <TextField label={"Min Budget"} fullWidth name="min_budget" inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', min: 1 }} variant="outlined" required />
                                                        </div>
                                                        <div className="col-md-6">
                                                            <TextField label={"Max Budget"} fullWidth name="max_budget" inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', min: 1 }} variant="outlined" required />
                                                        </div>
                                                    </div>
                                                </>
                                            ) : ""}
                                        </>
                                    )}
                                </Stack>
                            </LocalizationProvider>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        {/* <input type="button" className="btn btn-primary" id="" onClick={() => addNotes()} value="Add" /> */}
                        <input type="submit" className="btn btn-primary" id="" value="Add" />
                        <input type="button" className="btn btn-danger" id="" onClick={() => handleCloseLeadClose()} value="Cancel" />
                    </Modal.Footer>
                </form>
            </Modal>

            <input type="button" disabled={(props.leadDetails.lead_status.toLowerCase() == "closed" || props.leadDetails.lead_status.toLowerCase() == "booked") ? "disabled" : ""} className="m-2" value="Close Lead" onClick={() => handleCloseLeadShow()} />

        </>
    )
}

export default CloseLead