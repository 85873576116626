import './Config';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

import { useState } from 'react';
import moment from 'moment';

import { ContextData } from './Components/ContextData';
import Backdrop from '@mui/material/Backdrop';
import { BrowserRouter, Route, Routes, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { CircularProgress } from '@mui/material';

import Login from "./Login";
import Logout from "./Logout";
import NotFound from "./NotFound";
import NoAccess from "./NoAccess";
import Policy from "./Policy";
import SideDrawer from "./SideDrawer";
import Home from "./Home";
import LeadsList from './LeadsList';
import LeadDetails from './LeadDetails';
import BuilderAdd from './BuilderAdd';
import BuilderEdit from './BuilderEdit';
import BuildersList from './BuildersList';
import AssignEmployeeProject from './AssignEmployeeProject';
import LeadAdd from './LeadAdd';
import LeadEdit from './LeadEdit';
import ProjectAdd from './ProjectAdd';
import ProjectEdit from './ProjectEdit';
import ProjectsList from './ProjectsList';
import EmployeeAdd from './EmployeeAdd';
import EmployeeEdit from './EmployeeEdit';
import EmployeesList from './EmployeesList';
import ProjectCampaigns from './ProjectCampaigns';
import TotalPendingActivityList from './TotalPendingActivityList';
import TillDatePendingActivityList from './TillDatePendingActivityList';
import NoActivityList from './NoActivityList';
import CampaignAccounts from './CampaignAccounts';
import WebsiteApi from './WebsiteApi';
import ChangePassword from './ChangePassword';
import IncomingLeadsList from './IncomingLeadsList';
import LeadsSummary from './Reports/LeadsSummary';
import Reports from './Reports';
import RemindersSummary from './Reports/RemindersSummary';
import { useEffect } from 'react';
import axios from 'axios';
import ProjectRevenue from './ProjectRevenue';

function App() {

	var location = useLocation();
	var navigate = useNavigate();
	let [searchParams, setSearchParams] = useSearchParams();

	useEffect(() => {

		if (localStorage.getItem("login_data")) {
			var login_data = JSON.parse(localStorage.getItem("login_data"))

			if (login_data.employee_type == "MASTERUSER") {
			} else if (login_data.employee_type != "MASTERUSER") {
				checkBlockEmployeeWithLead(login_data.employee_id)
			} else {
				navigate("/noAccess")
			}
		}
	}, [location]);

	const checkBlockEmployeeWithLead = (employee_id) => {

		setProcessing(true)
		const getEmployeesUrl = global.config.server_url + "getEmployees.php";
		axios.post(getEmployeesUrl, { employee_id: employee_id }).then((getEmployeesResponse) => {
			if (getEmployeesResponse.data) {
				if (getEmployeesResponse.data.pending_lead_id) {
					var pending_lead_id = getEmployeesResponse.data.pending_lead_id
					context_data.setPrevPage(location.pathname)

					if ((location.pathname).includes("leadDetails")) {
						var id = searchParams.get("id");

						if (pending_lead_id != id) {
							navigate({ pathname: "/leadDetails/", search: "id=" + pending_lead_id, state: { id: pending_lead_id } })
						}
					} else {
						navigate({ pathname: "/leadDetails/", search: "id=" + pending_lead_id, state: { id: pending_lead_id } })
					}

				} else {
					console.log("no_pending_lead_id")
				}
				setProcessing(false)
			} else {
				alert("error in getting data")
				setProcessing(false)
			}
		}).catch(err => { console.log(err); setProcessing(false) });
	}

	const [prevPage, setPrevPage] = useState("");
	const [processing, setProcessing] = useState(false);
	const [reminderType, setReminderType] = useState("followup")
	const [pickerDatetime, setPickerDatetime] = useState(moment(new Date()).add((5 - ((moment(new Date())).minute() % 5)), 'm').toDate())

	const context_data = {
		prevPage: prevPage,
		setPrevPage: setPrevPage,
		processing: processing,
		setProcessing: setProcessing,
		reminderType: reminderType,
		setReminderType: setReminderType,
		pickerDatetime: pickerDatetime,
		setPickerDatetime: setPickerDatetime,
	}

	return (
		<ContextData.Provider value={context_data}>
			<div className="App">

				<Backdrop
					sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 2 }}
					open={processing}
				>
					<CircularProgress color="inherit" />
				</Backdrop>

				<Routes>
					<Route exact path="/" element={<Login />} />
					<Route exact path="/logout" element={<Logout />} />
					<Route exact path="/changePassword" element={<SideDrawer pageData={<ChangePassword />} />} />
					<Route exact path="/home" element={<SideDrawer pageData={<Home />} />} />

					<Route exact path="/allLeads" element={<SideDrawer pageData={<LeadsList />} />} />
					<Route exact path="/addLead" element={<SideDrawer pageData={<LeadAdd />} />} />
					<Route exact path="/editLead" element={<SideDrawer pageData={<LeadEdit />} />} />
					<Route exact path="/leadDetails" element={<SideDrawer pageData={<LeadDetails />} />} />
					{/* <Route exact path="/leadDetails" element={<LeadDetails />} /> */}

					<Route exact path="/addBuilder" element={<SideDrawer pageData={<BuilderAdd />} />} />
					<Route exact path="/editBuilder" element={<SideDrawer pageData={<BuilderEdit />} />} />
					<Route exact path="/allBuilders" element={<SideDrawer pageData={<BuildersList />} />} />

					<Route exact path="/addProject" element={<SideDrawer pageData={<ProjectAdd />} />} />
					<Route exact path="/editProject" element={<SideDrawer pageData={<ProjectEdit />} />} />
					<Route exact path="/allProjects" element={<SideDrawer pageData={<ProjectsList />} />} />

					<Route exact path="/addEmployee" element={<SideDrawer pageData={<EmployeeAdd />} />} />
					<Route exact path="/editEmployee" element={<SideDrawer pageData={<EmployeeEdit />} />} />
					<Route exact path="/allEmployees" element={<SideDrawer pageData={<EmployeesList />} />} />

					<Route exact path="/websiteApi" element={<SideDrawer pageData={<WebsiteApi />} />} />
					<Route exact path="/projectCampaigns" element={<SideDrawer pageData={<ProjectCampaigns />} />} />
					<Route exact path="/campaignAccounts" element={<SideDrawer pageData={<CampaignAccounts />} />} />
					<Route exact path="/incomingLeads" element={<SideDrawer pageData={<IncomingLeadsList />} />} />
					<Route exact path="/noActivityList" element={<SideDrawer pageData={<NoActivityList />} />} />
					<Route exact path="/totalPendingActivityList" element={<SideDrawer pageData={<TotalPendingActivityList />} />} />
					<Route exact path="/tillDatePendingActivityList" element={<SideDrawer pageData={<TillDatePendingActivityList />} />} />

					<Route exact path="/assignEmployee" element={<SideDrawer pageData={<AssignEmployeeProject />} />} />
					
					<Route exact path="/projectRevenue" element={<SideDrawer pageData={<ProjectRevenue />} />} />

					<Route exact path="/reports" element={<SideDrawer pageData={<Reports />} />} />
					<Route exact path="/leadsSummary" element={<SideDrawer pageData={<LeadsSummary />} />} />
					<Route exact path="/remindersSummary" element={<SideDrawer pageData={<RemindersSummary />} />} />

					<Route exact path="/policy" element={<Policy />} />
					<Route path="/noAccess" element={<SideDrawer pageData={<NoAccess />} />} />

					<Route path="*" element={<SideDrawer pageData={<NotFound />} />} />

				</Routes>

			</div>
		</ContextData.Provider >

	);
}

export default App;
