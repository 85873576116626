import axios from 'axios';
import React, { useContext, useCallback, useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { Col, Container, Row, Modal } from 'react-bootstrap';
import ucwords from 'ucwords';
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import { ContextData } from './Components/ContextData';
import { Box, LinearProgress } from '@mui/material';
import Breadcrumb from 'react-bootstrap/Breadcrumb'

var moment = require('moment')

const TotalPendingActivityList = (props) => {

    const context_data = useContext(ContextData)
    const [progressBar, setProgressBar] = useState(0)

    var navigate = useNavigate();
    var gridRef = useRef();
    var location = useLocation();

    const [loginData, setLoginData] = useState(false);
    const [userRightStatus, setUserRightStatus] = useState(false);

    useEffect(() => {
        if (localStorage.getItem("login_data")) {
            setLoginData(localStorage.getItem("login_data"))
            var login_data = JSON.parse(localStorage.getItem("login_data"))
            setLoginData(login_data)

            var isAvailable;

            if (login_data.employee_type == "MASTERUSER") {
                isAvailable = true;
            } else if (login_data.employee_rights) {
                var employee_rights_arr = (login_data.employee_rights).trim().split(",");
                var regex = new RegExp(employee_rights_arr.join("|"), "i");
                isAvailable = regex.test("ADD_LEAD") || login_data.employee_type == "MASTERUSER";
            } else {
                navigate("/noAccess")
            }
            setUserRightStatus(isAvailable)
            if (isAvailable != true) { navigate("/noAccess") }
            getRemindersCount(login_data.employee_type);
        } else {
            navigate("/logout")
        }

        if (!localStorage.getItem("employee_id")) {
            navigate("/logout");
        }

    }, [])

    async function getRemindersCount(employee_type) {
        context_data.setProcessing(true)
        if (employee_type == "MASTERUSER") {
            const getRemindersCountUrl = global.config.server_url + "getRemindersCount.php";
            await axios.post(getRemindersCountUrl, { employee_type: employee_type, all_data: true }).then((getRemindersCountResponse) => {
                if (getRemindersCountResponse.data) {
                    context_data.setProcessing(false)
                    var count = getRemindersCountResponse.data.count
                    count = (count / 100);
                    var loop_value = Math.ceil(count)
                    getReminders(loop_value, employee_type)
                } else {
                    console.log("error in getting remindersData count")
                    getAllReminders()
                    context_data.setProcessing(false)
                }
            }).catch(err => {
                console.log(err);
                getAllReminders();
                context_data.setProcessing(false)
            });
        } else if (employee_type == "EMPLOYEE") {
            var login_employee_id = (loginData['employee_id'] != undefined || loginData['employee_id'] != null) ? (loginData['employee_id']) : (localStorage.getItem("employee_id"));
            const getRemindersCountUrl = global.config.server_url + "getRemindersCount.php";
            await axios.post(getRemindersCountUrl, { employee_type: employee_type, all_data: true, login_employee_id: login_employee_id }).then((getRemindersCountResponse) => {
                if (getRemindersCountResponse.data) {
                    context_data.setProcessing(false)
                    var count = getRemindersCountResponse.data.count
                    count = (count / 100);
                    var loop_value = Math.ceil(count)
                    getReminders(loop_value, employee_type)
                } else {
                    console.log("error in getting remindersData count")
                    getAllReminders()
                    context_data.setProcessing(false)
                }
            }).catch(err => {
                console.log(err);
                getAllReminders();
                context_data.setProcessing(false)
            });
        }
    }

    async function getReminders(loop_value, employee_type) {
        var progress_add_value = 100 / loop_value;
        for (let i = 0; i < loop_value; i++) {
            const offset_value = i * 100;
            if (employee_type == "MASTERUSER") {
                const getRemindersUrl = global.config.server_url + "getRemindersUsingOffset.php";
                await axios.post(getRemindersUrl, { employee_type: employee_type, offset_value: offset_value }).then((getRemindersResponse) => {
                    if (getRemindersResponse.data) {
                        var reminders_data = getRemindersResponse.data
                        setRemindersData(old => [...old, ...reminders_data]);
                        context_data.setProcessing(false)
                    } else {
                        console.log("error in getting data")
                        context_data.setProcessing(false)
                    }
                }).catch(err => { console.log(err); context_data.setProcessing(false) });
            } else if (employee_type == "EMPLOYEE") {
                var login_employee_id = (loginData['employee_id'] != undefined || loginData['employee_id'] != null) ? (loginData['employee_id']) : (localStorage.getItem("employee_id"));
                const getRemindersUrl = global.config.server_url + "getRemindersUsingOffset.php";
                await axios.post(getRemindersUrl, { employee_type: employee_type, offset_value: offset_value, login_employee_id: login_employee_id }).then((getRemindersResponse) => {
                    if (getRemindersResponse.data) {
                        var reminders_data = getRemindersResponse.data
                        setRemindersData(old => [...old, ...reminders_data]);
                        context_data.setProcessing(false)
                    } else {
                        alert("error in getting data")
                        context_data.setProcessing(false)
                    }
                }).catch(err => { console.log(err); context_data.setProcessing(false) });
            }
            setProgressBar(i * progress_add_value)
        }
        setProgressBar(100)
    }

    const [remindersData, setRemindersData] = useState([]);

    const getAllReminders = async (employee_type) => {
        context_data.setProcessing(true)
        if (employee_type == "MASTERUSER") {
            const getAllRemindersUrl = global.config.server_url + "getAllReminders.php";
            axios.post(getAllRemindersUrl, { all_data: true }).then((allRemindersResponse) => {
                if (allRemindersResponse.data != false) {
                    setRemindersData(allRemindersResponse.data);
                    context_data.setProcessing(false)
                } else {
                    setRemindersData(false);
                    context_data.setProcessing(false)
                }
            }).catch(err => {
                console.log(err);
                context_data.setProcessing(false)
            });
        } else if (employee_type == "EMPLOYEE") {
            var login_employee_id = (loginData['employee_id'] != undefined || loginData['employee_id'] != null) ? (loginData['employee_id']) : (localStorage.getItem("employee_id"));

            const getAllRemindersUrl = global.config.server_url + "getAllReminders.php";
            axios.post(getAllRemindersUrl, { login_employee_id: login_employee_id }).then((allRemindersResponse) => {
                if (allRemindersResponse.data != false) {
                    setRemindersData(allRemindersResponse.data);
                    context_data.setProcessing(false)
                } else {
                    setRemindersData(false);
                    context_data.setProcessing(false)
                }
            }).catch(err => {
                console.log(err);
                context_data.setProcessing(false)
            });
        }
    }

    const [defaultColDef, setDefaultColDef] = useState(
        {
            resizable: true,
            sortable: true,
            autoHeight: true,
        }
    )

    const ucwords_value = (params) => {
        var column_name = (params.column.colId)
        var newValue = params.data[column_name] ? ucwords(params.data[column_name].toLowerCase()).replaceAll('_', ' ') : "-"
        return newValue
    }

    const lowercase_value = (params) => {
        var column_name = (params.column.colId)
        var newValue = params.data[column_name] ? (params.data[column_name].toLowerCase()).replaceAll('_', ' ') : "-"
        return newValue
    }

    const datetime_value = (params) => {
        var column_name = (params.column.colId)
        if (moment(params.data[column_name]).isValid()) {
            return moment(params.data[column_name]).format("DD-MMM-YYYY h:mm:ss A")
        } else if (moment(params.data[column_name], "YYYY-MM-DD h:mm:ss A", true).isValid()) {
            return moment(params.data[column_name], "YYYY-MM-DD h:mm:ss A", true).format("DD-MMM-YYYY h:mm:ss A")
        } else if (moment(params.data[column_name], "YYYY-MM-DD H:mm:ss", true).isValid()) {
            return moment(params.data[column_name], "YYYY-MM-DD H:mm:ss", true).format("DD-MMM-YYYY h:mm:ss A")
        } else {
            return params.data[column_name]
        }
    }

    const btnRenderer = (params) => {
        if (loginData.employee_type == "MASTERUSER") {
            let btn = document.createElement("button");
            let url = "/leadDetails/?id=" + params.data.id;
            btn.innerHTML = "Details";
            btn.classList = "table_list_btn btn btn-info"
            btn.onclick = function () {
                context_data.setPrevPage(location.pathname)
                const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
                if (newWindow) newWindow.opener = null
            };
            return btn;
        } else {
            let btn = document.createElement("button");
            btn.innerHTML = "Details";
            btn.classList = "table_list_btn btn btn-info"
            btn.onclick = function () {
                context_data.setPrevPage(location.pathname)
                navigate({ pathname: "/leadDetails/", search: "id=" + params.data.id, state: { id: params.data.id } })
            };
            return btn;
        }
    }

    const next_reminder_date_value = (params) => {
        var newValue = params.data.next_reminder
        var newValue_json = JSON.parse(newValue)
        if (moment(newValue_json.reminder).isValid()) {
            return moment(newValue_json.reminder).format("DD-MMM-YYYY h:mm:ss A")
        } else if (moment(newValue_json.reminder, "YYYY-MM-DD h:mm:ss A", true).isValid()) {
            return moment(newValue_json.reminder, "YYYY-MM-DD h:mm:ss A", true).format("DD-MMM-YYYY h:mm:ss A")
        } else if (moment(newValue_json.reminder, "YYYY-MM-DD H:mm:ss", true).isValid()) {
            return moment(newValue_json.reminder, "YYYY-MM-DD H:mm:ss", true).format("DD-MMM-YYYY h:mm:ss A")
        } else {
            return newValue_json.reminder
        }
    }


    const next_reminder_type_value = (params) => {
        var newValue = params.data.next_reminder
        var newValue_json = JSON.parse(newValue)
        var data = ucwords(newValue_json.activity)
        return data
    }

    const autoSizeAll = useCallback((skipHeader) => {
        const allColumnIds = [];
        gridRef.current.columnApi.getAllColumns().forEach((column) => {
            allColumnIds.push(column.getId());
        });
        gridRef.current.columnApi.autoSizeColumns(allColumnIds, skipHeader);
    }, []);

    const cellClassRules = {
        "alert alert-danger": params => ((moment(params.value, "DD-MMM-YYYY h:mm:ss A")).diff(moment()) < 0)
    };

    const onFilterTextBoxChanged = useCallback(() => {
        gridRef.current.api.setQuickFilter(
            document.getElementById('filter-text-box').value
        );
    }, []);

    const openLead = (params) => {
        context_data.setPrevPage(location.pathname)
        if (loginData.employee_type == "MASTERUSER") {
            let url = "/leadDetails/?id=" + params.data.id;
            const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
            if (newWindow) newWindow.opener = null
        } else {
            navigate({ pathname: "/leadDetails/", search: "id=" + params.data.id, state: { id: params.data.id } })
        }
    }


    const attended_value = (params) => {
        if (params.data.attended_id) { return "Yes" } else { return "No" }
    }

    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <Breadcrumb>
                            <Breadcrumb.Item onClick={() => navigate("/home")}>Home</Breadcrumb.Item>
                            <Breadcrumb.Item active>Total Pending Activity List</Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                </div>
            </div>
            <Container fluid>
                <Row>
                    <div className="col-12" style={{ marginBottom: "15px" }}>
                        <Box sx={{ width: '100%' }}>
                            <LinearProgress variant="determinate" color={progressBar == 100 ? "success" : "secondary"} value={progressBar} />
                        </Box>
                    </div>
                    <Col>
                        <Row className='my-2'>
                            <Col sm></Col>
                            <Col sm>
                                <h3 className='text-center'>Total Pending Activity List</h3>
                            </Col>
                            <Col sm className='text-end'>
                                <input className='form-control' type="text" id="filter-text-box" placeholder="Search..." onInput={onFilterTextBoxChanged} />
                            </Col>
                        </Row>

                        {(remindersData) ? (
                            <div className="ag-theme-alpine" style={{ height: "75vh", width: "100%" }}>
                                <AgGridReact
                                    defaultColDef={defaultColDef}
                                    rowData={remindersData}
                                    pagination={true}
                                    paginationAutoPageSize={true}
                                    animateRows={true}
                                    ref={gridRef}
                                    onGridReady={() => autoSizeAll(false)}
                                    onFirstDataRendered={() => autoSizeAll(false)}
                                >
                                    <AgGridColumn width="100" headerName="Sl No" valueGetter={"node.rowIndex+1"} ></AgGridColumn>
                                    <AgGridColumn onCellClicked={openLead} cellClass={"column_lead_id"} filter='agTextColumnFilter' headerName="Lead ID" field="id" ></AgGridColumn>
                                    {loginData.employee_type == "MASTERUSER" ? (<AgGridColumn filter='agTextColumnFilter' headerName="Lead Owner" field="lead_owner_name" valueGetter={ucwords_value}></AgGridColumn>) : ""}
                                    <AgGridColumn filter='agTextColumnFilter' headerName="Name" field="name" valueGetter={ucwords_value} ></AgGridColumn>
                                    {loginData.employee_type == "MASTERUSER" ? (<AgGridColumn filter='agTextColumnFilter' headerName="Mobile" field="mobile" ></AgGridColumn>) : ""}
                                    <AgGridColumn filter='agTextColumnFilter' headerName="Project" field="project_name" valueGetter={ucwords_value}></AgGridColumn>
                                    <AgGridColumn filter='agTextColumnFilter' headerName="Status" field="lead_status" valueGetter={ucwords_value} ></AgGridColumn>
                                    <AgGridColumn filter='agTextColumnFilter' headerName="Reminder Type" field="next_reminder" valueGetter={next_reminder_type_value}></AgGridColumn>
                                    <AgGridColumn cellClassRules={cellClassRules} filter='agTextColumnFilter' headerName="Reminder" field="next_reminder" valueGetter={next_reminder_date_value}></AgGridColumn>
                                    <AgGridColumn headerName="Actions" cellRenderer={btnRenderer} ></AgGridColumn>
                                </AgGridReact>
                            </div>
                        ) : (<div><h3 className="text-center m-5">No Data Available</h3></div>)}

                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default TotalPendingActivityList