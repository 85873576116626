import React, { useContext, useEffect, useState } from 'react'
import { Card } from 'react-bootstrap'
import axios from 'axios';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Reminders from './Components/Reminders';

import MoveToInboxIcon from '@mui/icons-material/MoveToInbox';
import ListIcon from '@mui/icons-material/List';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import ErrorIcon from '@mui/icons-material/Error';
import PendingIcon from '@mui/icons-material/Pending';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import CachedIcon from '@mui/icons-material/Cached';
import AssessmentIcon from '@mui/icons-material/Assessment';
import { ContextData } from './Components/ContextData';

var moment = require("moment")

const Home = () => {
    const context_data = useContext(ContextData)
    var navigate = useNavigate();
    var location = useLocation();

    const [loginData, setLoginData] = useState(false);

    useEffect(() => {
        if (localStorage.getItem("login_data")) {
            setLoginData(localStorage.getItem("login_data"))
            var login_data = JSON.parse(localStorage.getItem("login_data"))
            setLoginData(login_data)
            getCountIncoming(login_data.employee_type)
            getAllLeadsCount(login_data.employee_type)
            getUnattendedLeadsCount(login_data.employee_type)
            getCountNoActivity(login_data.employee_type)
            getCountPendingActivity(login_data.employee_type)


            const interval = setInterval(() => {
                refreshPage();
            }, 15000);

            return () => clearInterval(interval);

        } else {
            navigate("/logout")
        }

        if (!localStorage.getItem("employee_id")) {
            navigate("/logout");
        }
    }, [])

    const [countNoActivity, setCountNoActivity] = useState(0)
    const [countIncoming, setCountIncoming] = useState(0)
    const [countAllLeads, setCountAllLeads] = useState(0)
    const [countUnattendedLeads, setCountUnattendedLeads] = useState(0)
    const [countTotalPendingActivity, setCountTotalPendingActivity] = useState(0)
    const [countTillDatePendingActivity, setCountTillDatePendingActivity] = useState(0)
    const [refreshTime, setRefreshTime] = useState(moment(new Date()).format("DD-MMM-YYYY h:mm:ss A"));

    const refreshPage = () => {
        let current_datetime = moment(new Date()).format("DD-MMM-YYYY h:mm:ss A");
        setRefreshTime(current_datetime);
        console.log(current_datetime);

        getCountIncoming(loginData.employee_type)
        getAllLeadsCount(loginData.employee_type)
        getUnattendedLeadsCount(loginData.employee_type)
        getCountNoActivity(loginData.employee_type)
        getCountPendingActivity(loginData.employee_type)
    }

    const getCountIncoming = (employee_type) => {
        if (employee_type.toUpperCase() == "MASTERUSER") {
            const getCountIncomingUrl = global.config.server_url + "getIncomingLeadsCount.php";
            axios.post(getCountIncomingUrl, { all_data: true, count_data: true }).then((getCountIncomingResponse) => {
                if (getCountIncomingResponse.data) {
                    setCountIncoming(getCountIncomingResponse.data["count"]);
                } else {
                    console.log(getCountIncomingResponse)
                }
            }).catch(err => console.log(err));
        } else if (employee_type.toUpperCase() == "EMPLOYEE") {
            var login_employee_id = (loginData['employee_id'] != undefined || loginData['employee_id'] != null) ? (loginData['employee_id']) : (localStorage.getItem("employee_id"));
            const getCountIncomingUrl = global.config.server_url + "getIncomingLeadsCount.php";
            axios.post(getCountIncomingUrl, { login_employee_id: login_employee_id, count_data: true }).then((getCountIncomingResponse) => {
                if (getCountIncomingResponse.data) {
                    setCountIncoming(getCountIncomingResponse.data["count"]);
                } else {
                    console.log(getCountIncomingResponse)
                }
            }).catch(err => console.log(err));
        }
    }

    const getAllLeadsCount = (employee_type) => {
        console.log(employee_type)
        if (employee_type.toUpperCase() == "MASTERUSER") {
            const getAllLeadsCountUrl = global.config.server_url + "getAllLeadsCount.php";
            axios.post(getAllLeadsCountUrl, { all_data: true, count_data: true }).then((getAllLeadsCountResponse) => {
                if (getAllLeadsCountResponse.data) {
                    setCountAllLeads(getAllLeadsCountResponse.data["count"]);
                } else {
                    console.log(getAllLeadsCountResponse)
                }
            }).catch(err => console.log(err));
        } else if (employee_type.toUpperCase() == "EMPLOYEE") {
            var login_employee_id = (loginData['employee_id'] != undefined || loginData['employee_id'] != null) ? (loginData['employee_id']) : (localStorage.getItem("employee_id"));
            const getAllLeadsCountUrl = global.config.server_url + "getAllLeadsCount.php";
            axios.post(getAllLeadsCountUrl, { login_employee_id: login_employee_id, count_data: true }).then((getAllLeadsCountResponse) => {
                if (getAllLeadsCountResponse.data) {
                    setCountAllLeads(getAllLeadsCountResponse.data["count"]);
                } else {
                    console.log(getAllLeadsCountResponse)
                }
            }).catch(err => console.log(err));
        }
    }

    const getUnattendedLeadsCount = (employee_type) => {
        if (employee_type.toUpperCase() == "MASTERUSER") {
            const getUnattendedLeadsCountUrl = global.config.server_url + "getUnattendedLeadsCount.php";
            axios.post(getUnattendedLeadsCountUrl, { all_data: true, count_data: true }).then((getUnattendedLeadsCountResponse) => {
                if (getUnattendedLeadsCountResponse.data) {
                    setCountUnattendedLeads(getUnattendedLeadsCountResponse.data["count"]);
                } else {
                    console.log(getUnattendedLeadsCountResponse)
                }
            }).catch(err => console.log(err));
        } else if (employee_type.toUpperCase() == "EMPLOYEE") {
            var login_employee_id = (loginData['employee_id'] != undefined || loginData['employee_id'] != null) ? (loginData['employee_id']) : (localStorage.getItem("employee_id"));
            const getUnattendedLeadsCountUrl = global.config.server_url + "getUnattendedLeadsCount.php";
            axios.post(getUnattendedLeadsCountUrl, { login_employee_id: login_employee_id, count_data: true }).then((getUnattendedLeadsCountResponse) => {
                if (getUnattendedLeadsCountResponse.data) {
                    setCountUnattendedLeads(getUnattendedLeadsCountResponse.data["count"]);
                } else {
                    console.log(getUnattendedLeadsCountResponse)
                }
            }).catch(err => console.log(err));
        }
    }

    const getCountNoActivity = (employee_type) => {
        if (employee_type.toUpperCase() == "MASTERUSER") {
            const getCountNoActivityUrl = global.config.server_url + "getNoActivitiesCount.php";
            axios.post(getCountNoActivityUrl, { all_data: true, count_data: true }).then((getCountNoActivityResponse) => {
                if (getCountNoActivityResponse.data) {
                    setCountNoActivity(getCountNoActivityResponse.data["count"]);
                } else {
                    console.log(getCountNoActivityResponse)
                }
            }).catch(err => console.log(err));
        } else if (employee_type.toUpperCase() == "EMPLOYEE") {
            var login_employee_id = (loginData['employee_id'] != undefined || loginData['employee_id'] != null) ? (loginData['employee_id']) : (localStorage.getItem("employee_id"));
            const getCountNoActivityUrl = global.config.server_url + "getNoActivitiesCount.php";
            axios.post(getCountNoActivityUrl, { login_employee_id: login_employee_id, count_data: true }).then((getCountNoActivityResponse) => {
                if (getCountNoActivityResponse.data) {
                    setCountNoActivity(getCountNoActivityResponse.data["count"]);
                } else {
                    console.log(getCountNoActivityResponse)
                }
            }).catch(err => console.log(err));
        }
    }

    const getCountPendingActivity = (employee_type) => {
        if (employee_type.toUpperCase() == "MASTERUSER") {
            const getCountPendingActivityUrl = global.config.server_url + "getAllRemindersCount.php";
            axios.post(getCountPendingActivityUrl, { all_data: true }).then((getCountPendingActivityResponse) => {
                if (getCountPendingActivityResponse.data) {
                    setCountTotalPendingActivity(getCountPendingActivityResponse.data["count"]);
                } else {
                    console.log(getCountPendingActivityResponse)
                }
            }).catch(err => console.log(err));

            const getTillDateRemindersCountUrl = global.config.server_url + "getTillDateRemindersCount.php";
            axios.post(getTillDateRemindersCountUrl, { all_data: true }).then((getTillDateRemindersCountResponse) => {
                if (getTillDateRemindersCountResponse.data) {
                    setCountTillDatePendingActivity(getTillDateRemindersCountResponse.data["count"]);
                } else {
                    console.log(getTillDateRemindersCountResponse)
                }
            }).catch(err => console.log(err));

        } else if (employee_type.toUpperCase() == "EMPLOYEE") {
            var login_employee_id = (loginData['employee_id'] != undefined || loginData['employee_id'] != null) ? (loginData['employee_id']) : (localStorage.getItem("employee_id"));

            const getCountPendingActivityUrl = global.config.server_url + "getAllRemindersCount.php";
            axios.post(getCountPendingActivityUrl, { login_employee_id: login_employee_id }).then((getCountPendingActivityResponse) => {
                if (getCountPendingActivityResponse.data) {
                    setCountTotalPendingActivity(getCountPendingActivityResponse.data["count"]);
                } else {
                    console.log(getCountPendingActivityResponse)
                }
            }).catch(err => console.log(err));

            const getTillDateRemindersCountUrl = global.config.server_url + "getTillDateRemindersCount.php";
            axios.post(getTillDateRemindersCountUrl, { login_employee_id: login_employee_id }).then((getTillDateRemindersCountResponse) => {
                if (getTillDateRemindersCountResponse.data) {
                    setCountTillDatePendingActivity(getTillDateRemindersCountResponse.data["count"]);
                } else {
                    console.log(getTillDateRemindersCountResponse)
                }
            }).catch(err => console.log(err));
        }
    }

    const submitClickToCall = (e) => {
        e.preventDefault();
        var lead_id = e.target.lead_id.value
        var employee_type = loginData.employee_type
        if (employee_type == "MASTERUSER") {
            context_data.setProcessing(true)
            const getLeadsUrl = global.config.server_url + "getLeads.php";
            axios.post(getLeadsUrl, { id: lead_id, employee_type: employee_type }).then((getLeadsResponse) => {
                if (getLeadsResponse.data) {
                    context_data.setProcessing(false)
                    // console.log(getLeadsResponse.data);
                    submitCall(getLeadsResponse.data)
                } else {
                    context_data.setProcessing(false)
                    // console.log(getLeadsResponse)
                    alert("Lead ID not found")
                }
            }).catch(err => { console.log(err); context_data.setProcessing(false) });
        } else if (employee_type == "EMPLOYEE") {
            context_data.setProcessing(true)
            var login_employee_id = (loginData['employee_id'] != undefined || loginData['employee_id'] != null) ? (loginData['employee_id']) : (localStorage.getItem("employee_id"));
            const getLeadsUrl = global.config.server_url + "getLeads.php";
            axios.post(getLeadsUrl, { id: lead_id, employee_type: employee_type, login_employee_id: login_employee_id }).then((getLeadsResponse) => {
                if (getLeadsResponse.data) {
                    context_data.setProcessing(false)

                    if (getLeadsResponse.data.lead_status.toLowerCase() == "booked") {
                        context_data.setPrevPage(location.pathname)
                        submitCall(getLeadsResponse.data)
                        navigate({ pathname: "/leadDetails/", search: "id=" + lead_id, state: { id: lead_id } })
                    } else {
                        blockEmployeeWithLead(getLeadsResponse.data)
                        submitCall(getLeadsResponse.data)
                    }

                } else {
                    context_data.setProcessing(false)
                    // console.log(getLeadsResponse)
                    alert("Lead ID is not in your account or Lead ID not found")
                }
            }).catch(err => { console.log(err); context_data.setProcessing(false) });
        }
    }

    const submitCall = async (lead_details) => {
        changeAttendedBy(lead_details)
        const addCallUrl = global.config.server_url + "addCall.php";
        let notesData = "Clicked on Call Button";
        notesData = notesData.replaceAll("'", "");

        const addCallData = {
            notes_data: notesData,
            login_employee_id: localStorage.getItem("employee_id"),
            login_employee_name: localStorage.getItem("employee_name"),
            id: lead_details.id
        }

        context_data.setProcessing(true);

        await axios.post(addCallUrl, addCallData).then((response) => {
            if (response.data == true) {
                context_data.setProcessing(false);
            } else {
                console.log("Error in Adding Call Data");
                context_data.setProcessing(false);
            }
        }).catch(err => { console.log(err); console.log("Error in Adding Call Data"); context_data.setProcessing(false); });
        openInNewTabCall(lead_details.mobile)
    }

    const changeAttendedBy = async (lead_details) => {

        if (localStorage.getItem("employee_id").toUpperCase() == lead_details.lead_owner_id.toUpperCase()) {
            const changeAttendedByUrl = global.config.server_url + "changeAttendedBy.php";
            const changeAttendedByData = {
                login_employee_id: (loginData['employee_id'] != undefined || loginData['employee_id'] != null) ? (loginData['employee_id']) : (localStorage.getItem("employee_id")),
                login_employee_name: (loginData['employee_name'] != undefined || loginData['employee_name'] != null) ? (loginData['employee_name']) : (localStorage.getItem("employee_name")),
                id: lead_details.id
            }
            axios.post(changeAttendedByUrl, changeAttendedByData).then((ChangeAttendedByResponse) => {
                if (ChangeAttendedByResponse.data == true) {
                    console.log("Changed attended by");
                }
                else {
                    console.log("error in changing attended by");
                }
            }).catch(err => { console.log(err); console.log("error in changing attended by"); });
        }
    }

    const openInNewTabCall = (number) => {
        var url = "tel:" + number;
        var click_to_call_a_tag = document.getElementById("click_to_call_a_tag")
        click_to_call_a_tag.setAttribute("href", url);
        click_to_call_a_tag.click()
        // const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
        // if (newWindow) newWindow.opener = null
    }

    const blockEmployeeWithLead = async (lead_details) => {
        const blockEmployeeUrl = global.config.server_url + "blockEmployee.php";


        var id = lead_details.id;
        var next_reminder = lead_details.next_reminder;
        var login_employee_id = localStorage.getItem("employee_id");

        const blockEmployeeData = {
            id: id,
            login_employee_id: login_employee_id,
        }

        context_data.setProcessing(true);
        await axios.post(blockEmployeeUrl, blockEmployeeData).then((response) => {
            if (response.data == true) {
                context_data.setPrevPage(location.pathname)
                context_data.setProcessing(false);
                navigate({ pathname: "/leadDetails/", search: "id=" + id, state: { id: id } })
            } else {
                alert("Error in Adding Data");
                context_data.setProcessing(false);
            }
        }).catch(err => { console.log(err); alert("Error in Adding Data"); context_data.setProcessing(false); });

        // if (next_reminder != "undefined" && next_reminder != undefined && next_reminder != null) {
        //     return
        // }
        // else {

        //     await axios.post(blockEmployeeUrl, blockEmployeeData).then((response) => {
        //         if (response.data == true) {
        //             context_data.setProcessing(false);
        //             navigate({ pathname: "/leadDetails/", search: "id=" + id, state: { id: id } })
        //         } else {
        //             alert("Error in Adding Data");
        //             context_data.setProcessing(false);
        //         }
        //     }).catch(err => { console.log(err); alert("Error in Adding Data"); context_data.setProcessing(false); });
        // }
    }

    return (
        <>
            <section className="section_home">
                <div className="container-fluid">

                    <div className="d-none" style={{ display: "none" }}><a className='d-none' style={{ display: "none" }} href="" id="click_to_call_a_tag"></a></div>

                    <div className="row">
                        <div className="col-md-8">
                            <div className="row">
                                <div className="col-12 div_col_home_click_to_call">
                                    <form onSubmit={submitClickToCall}>
                                        <div className="input-group mb-3">
                                            <input type="text" className="form-control" name="lead_id" placeholder="Enter Lead ID" aria-label="Enter Lead ID" required />
                                            <div className="input-group-append">
                                                <button className="btn btn-primary" type="submit">Click to Call</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div className="col-12">
                                    <div onClick={() => { document.getElementById("span_refresh").value = "Refreshing..."; refreshPage(); }} style={{ cursor: "pointer" }}>
                                        <span id="span_refresh">Last refreshed at: {refreshTime} </span>
                                        <CachedIcon className="refreshIcon" onClick={() => { document.getElementById("span_refresh").value = "Refreshing..."; refreshPage(); }} />
                                    </div>
                                </div>

                                <div className="col-6 col-md-3 div_incoming">
                                    <Card onClick={() => navigate("/incomingLeads")}>
                                        <MoveToInboxIcon className='home_fa_icon' />
                                        <div className="text-center">Incoming Leads</div>
                                        <div><h4><b>{countIncoming > 0 ? " (" + countIncoming + ")" : ""}</b></h4></div>
                                    </Card>
                                </div>

                                <div className="col-6 col-md-3 div_all_enquiry">
                                    <Card onClick={() => navigate("/allLeads")}>
                                        <ListIcon className='home_fa_icon' />
                                        <div className="text-center">All Leads</div>
                                        <div><h4><b>{countAllLeads > 0 ? "(" + countUnattendedLeads + " / " + countAllLeads + ")" : ""}</b></h4></div>
                                    </Card>
                                </div>

                                <div className="col-6 col-md-3 div_add_enquiry">
                                    <Card onClick={() => navigate("/addLead")}>
                                        <PlaylistAddIcon className='home_fa_icon' />
                                        <div className="text-center">New Lead</div>
                                    </Card>
                                </div>
                                <div className="col-6 col-md-3 div_no_activity">
                                    <Card onClick={() => navigate("/noActivityList")}>
                                        <ErrorIcon className='home_fa_icon' />
                                        <div className="text-center">No Activity List</div>
                                        <div><h4><b>{countNoActivity > 0 ? " (" + countNoActivity + ")" : ""}</b></h4></div>
                                    </Card>
                                </div>
                                <div className="col-6 col-md-3 div_total_pending_activity">
                                    <Card onClick={() => navigate("/totalPendingActivityList")}>
                                        <PendingIcon className='home_fa_icon' />
                                        <div className="text-center">Total Pending Activity List</div>
                                        <div><h4><b>{countTotalPendingActivity > 0 ? " (" + countTotalPendingActivity + ")" : ""}</b></h4></div>
                                    </Card>
                                </div>
                                <div className="col-6 col-md-3 div_tillDate_pending_activity">
                                    <Card onClick={() => navigate("/tillDatePendingActivityList")}>
                                        <PendingActionsIcon className='home_fa_icon' />
                                        <div className="text-center">Till Date Pending Activity List</div>
                                        <div><h4><b>{countTillDatePendingActivity > 0 ? " (" + countTillDatePendingActivity + ")" : ""}</b></h4></div>
                                    </Card>
                                </div>

                                <div className="col-6 col-md-3 div_reports">
                                    <Card onClick={() => navigate("/leadsSummary")}>
                                        <AssessmentIcon className='home_fa_icon' />
                                        <div className="text-center">Leads Summary</div>
                                    </Card>
                                </div>

                                {/* {(loginData.employee_type == "MASTERUSER") && (<>
                                    <div className="col-6 col-md-3 div_reports">
                                        <Card onClick={() => navigate("/reports")}>
                                            <AssessmentIcon className='home_fa_icon' />
                                            <div className="text-center">Reports</div>
                                        </Card>
                                    </div>
                                </>)} */}

                            </div>

                            {/* <div className="row">
								<div className="col-md-6">
									<Notifications/>
								</div>
							</div> */}
                        </div>
                        <div className="col-md-4">
                            <Reminders />
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default Home